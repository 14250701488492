import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { queueGuard } from './core/guards/queue.guard';

const routes: Routes = [
  // { path: '', redirectTo: 'vip/', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () =>
      import('./features/components/landing-page/landing-page.module').then(
        m => m.LandingPageModule
      )
  },
  {
    path: 'vip/:profileUrl',
    loadChildren: () => import('./features/components/vip/vip.module').then(m => m.VipModule)
  },
  {
    path: 'vip/:profileUrl/sign-up',
    loadChildren: () =>
      import('./features/components/sign-up/sign-up.module').then(m => m.SignUpModule)
  },
  {
    path: 'vip/:profileUrl/only-desktop',
    loadChildren: () =>
      import('./features/components/only-desktop/only-desktop.module').then(m => m.OnlyDesktopModule)
  },
  {
    path: 'vip/:profileUrl/mobile-permission-info',
    loadChildren: () =>
      import('./features/components/mobile-permission-info/mobile-permission-info.module').then(m => m.MobilePermissionInfoModule)
  },
  {
    path: 'vip/:profileUrl/billing-information',
    loadChildren: () =>
      import('./features/components/billing-information/billing-information.module').then(
        m => m.BillingInformationModule
      )
  },
  // {
  //   path: 'vip/:profileUrl/setting-devices',
  //   loadChildren: () =>
  //     import('./features/components/setting-devices/setting-devices.module').then(
  //       m => m.SettingDevicesModule
  //     )
  // },
  // {
  //   path: 'vip/:profileUrl/complete-payment',
  //   loadChildren: () =>
  //     import('./features/components/complete-payment/complete-payment.module').then(
  //       m => m.CompletePaymentModule
  //     )
  // },
  {
    path: 'meeting/:profileId',
    loadChildren: () =>
      import('./features/components/meeting/meeting.module').then(m => m.MeetingModule)
  },
  // {
  //   path: 'vip/:profileUrl/snap-photo',
  //   loadChildren: () =>
  //     import('./features/components/snap-photo/snap-photo.module').then(m => m.SnapPhotoModule)
  // },
  {
    path: 'vip/:profileUrl/waiting-room',
    loadChildren: () =>
      import('./features/components/waiting-room/waiting-room.module').then(
        m => m.WaitingRoomModule
      ),
    canActivate: [queueGuard]
  },
  // {
  //   path: 'vip/:profileUrl/extend-call-payment',
  //   loadChildren: () =>
  //     import('./features/components/extend-call-payment/extend-call-payment.module').then(
  //       m => m.ExtendCallPaymentModule
  //     )
  // },
  {
    path: 'unsubscribe',
    loadChildren: () =>
      import('./features/components/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
